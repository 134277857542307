import combineQuery from 'graphql-combine-query';
import defaults from 'lodash/defaults';
import uniq from 'lodash/uniq';
import { parsePlaceholders } from '@magalu/mixer-structure';

import { loadGraphql } from './loaders';
import QueryDefinitionCleaner from './QueryDefinitionCleaner';
import logger from './logger';

export const keyFromDataSource = (modules, key) => {
  return uniq(
    modules
      ?.reduce((acc, { module, name, dataSource }) => {
        const moduleDataSource = { ...module?.dataSource, ...dataSource };
        if (moduleDataSource && module?.ssr) {
          const mappedKeys = Object.values(moduleDataSource)
            .map(data => {
              return {
                [key]: data[key],
                name,
              };
            })
            .filter(Boolean);
          return [...acc, ...mappedKeys];
        }
        return acc;
      }, [])
      .flat()
  );
};

const getQueriesVariables = structure => {
  const queriesVariables = (structure?.components || structure?.serverComponents)?.reduce(
    (acc, comp) => {
      const childVariables = comp.components?.reduce((res, item) => {
        return {
          ...res,
          ...parsePlaceholders(item?.queryVariables, structure),
        };
      }, {});
      const parentVariables = parsePlaceholders(comp?.queryVariables, structure);
      return { ...acc, ...parentVariables, ...childVariables };
    },
    {}
  );

  const { filters, ...routeVariables } = structure?.route || {};
  const {
    location,
    pmdPromoter,
    customerId,
    partnerId,
    searchExperiment,
    magaluExperiment,
  } = structure?.cookies || {};
  const site = structure?.site || '';
  const pagePath = structure?.canonicalPath;
  const userAgent = structure?.userAgent;
  const name = structure?.name;
  const searchExperimentId = structure?.searchExperimentId;

  routeVariables.pmdPromoter = pmdPromoter;
  routeVariables.customerId = customerId;
  routeVariables.partnerId = partnerId;
  routeVariables.searchExperiment = searchExperiment;
  routeVariables.site = site;
  routeVariables.variationId = routeVariables.productId;
  routeVariables.pagePath = pagePath;
  routeVariables.experiment = magaluExperiment;
  routeVariables.pageName = name;
  routeVariables.userAgent = userAgent;
  routeVariables.bypass = !!routeVariables.bypass;
  routeVariables.trackId = searchExperimentId;

  routeVariables.pageSize = routeVariables.pageSize
    ? Number(routeVariables.pageSize)
    : undefined;

  if (location) {
    routeVariables.zipCode = location?.zipCode;
    routeVariables.latitude = parseFloat(location?.zipCodeLatitude);
    routeVariables.longitude = parseFloat(location?.zipCodeLongitude);
  }

  if (filters) {
    routeVariables.filters = filters.map(({ type, value, values, min, max }) => ({
      max,
      min,
      type,
      value,
      values,
    }));
  }

  return defaults(queriesVariables, routeVariables);
};

const getMultipleModulesInfo = modules => {
  return modules.reduce((acc, module) => {
    if (acc[module.name]) {
      const newVariables = acc[module.name].queryVariables.concat(module.queryVariables);
      return { ...acc, [module.name]: { queryVariables: newVariables } };
    }
    return { ...acc, [module.name]: { queryVariables: module.queryVariables } };
  }, {});
};

const buildFragmentsVariables = fragments =>
  fragments?.reduce((acc, { fragments: fragment }) => {
    if (fragment) {
      // all fragments will be true if declared
      if (typeof fragment === 'string') {
        return { ...acc, [fragment]: true };
      }

      const items = fragment?.reduce((obj, key) => ({ ...obj, [key]: true }), {});
      return { ...acc, ...items };
    }
    return acc;
  }, {});

const buildVariables = ({ structure, modules }) => {
  if (!structure) return {};
  const fragments = keyFromDataSource(modules, 'fragments');
  const showFragments = buildFragmentsVariables(fragments);
  const queryVariables = getQueriesVariables(structure);
  logger.debug(
    `[data source] - build query with variables: ${JSON.stringify(
      queryVariables,
      null,
      4
    )}`
  );

  return { ...queryVariables, ...showFragments };
};

const loadQueries = async (ids = []) => {
  const queries = await loadGraphql();
  logger.debug(`[data source] - build query within IDs: ${ids}`);
  return ids.map(query => queries[query]);
};

const buildMultipleQueries = async ({ modules }) => {
  const multipleModules = modules.filter(module => module.multiple);
  if (multipleModules.length === 0) return [];

  const multipleQueriesIds = keyFromDataSource(multipleModules, 'query');
  const queriesInfo = getMultipleModulesInfo(modules);
  const queries = await loadGraphql();
  return multipleQueriesIds.reduce((acc, { name, query }) => {
    return [
      ...acc,
      { query: queries[query], variables: queriesInfo[name].queryVariables },
    ];
  }, []);
};

const buildQuery = async ({ modules }) => {
  const cleaner = new QueryDefinitionCleaner();
  const singleQueries = await loadQueries(
    keyFromDataSource(
      modules.filter(module => !module.multiple),
      'query'
    ).map(({ query }) => query)
  );

  const multipleQueries = await buildMultipleQueries({ modules });
  const rootQuery = combineQuery('root');

  const singleAdded = singleQueries.reduce(
    (combinedQuery, query) =>
      query ? combinedQuery.add(cleaner.clean(query)) : combinedQuery,
    rootQuery
  );

  return multipleQueries.reduce(
    (combinedQuery, { query, variables }) =>
      query ? combinedQuery.addN(cleaner.clean(query), variables) : combinedQuery,
    singleAdded
  );
};

export {
  buildFragmentsVariables,
  buildMultipleQueries,
  buildVariables,
  getMultipleModulesInfo,
  getQueriesVariables,
  loadQueries,
};

export default buildQuery;
